import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Language from "./language"
import Seal from "../images/seal"
import LeavingDisclaimer from "./leavingDisclaimer"
import { useStaticQuery, Link, graphql } from 'gatsby';

export default function Footer(){
    const [modalState, setModalState] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const intl = useIntl();
    const isFaq = typeof window !== 'undefined' ? window.location.pathname.includes('faq') : false;
    const isResources = typeof window !== 'undefined' ? window.location.pathname.includes('resources') : false;
    const footerData =  useStaticQuery(graphql`
        query {
          footerSection {
              footerSection {
                  contentItemId
                  titleEnglish
                  titleSpanish
                  titleChinese
                  titleHaitianCreole
                  titlePortuguese
                  titlePunjabi
                  links {
                      contentItemIds
                  }
              }
          },
          links {
              links {
                  contentItemId
                  titleEnglish
                  titleSpanish
                  titleChinese
                  titleHaitianCreole
                  titlePortuguese
                  titlePunjabi
                  uRL
                  urlSpanish
                  urlChinese
                  urlHaitianCreole
                  urlPortuguese
                  urlPunjabi
              }
          },
          pageTranslations {
            pageTranslations {
              stayConnected
              footerLanguages
              localization {
                culture
              }
            }
          }
        }
    `);

    function footerMap() {
      switch (intl.locale) {
        case 'es':
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titleSpanish || column.titleEnglish,
            links: column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titleSpanish || link.titleEnglish, url: link.urlSpanish || link.uRL}))[0]) || [],
          }))
        case 'zh':
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titleChinese || column.titleEnglish,
            links: column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titleChinese || link.titleEnglish, url: link.urlChinese || link.uRL}))[0]) || [],
          }))
        case 'ht':
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titleHaitianCreole || column.titleEnglish,
            links: column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titleHaitianCreole || link.titleEnglish, url: link.urlHaitianCreole || link.uRL}))[0]) || [],
          }))
        case 'pt':
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titlePortuguese || column.titleEnglish,
            links: column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titlePortuguese || link.titleEnglish, url: link.urlPortuguese || link.uRL}))[0]) || [],
          }))
        case 'pa':
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titlePunjabi || column.titleEnglish,
            links: column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titlePunjabi || link.titleEnglish, url: link.urlPunjabi || link.uRL}))[0]) || [],
          }))
        default: 
          return footerData.footerSection.footerSection.map(column => ({
            contentItemId: column.contentItemId,
            title: column.titleEnglish,
            links:  column.links?.contentItemIds.map(id => footerData.links.links.filter(link => link.contentItemId === id).map(link => 
              ({title: link.titleEnglish, url: link.uRL}))[0]) || [],
          }))
      }
    }

    function footerLang() {
      const pageTranslations = footerData.pageTranslations.pageTranslations;
      const english = pageTranslations.filter(page => page.localization.culture === 'en-US')[0].footerLanguages;
      switch (intl.locale) {
        case 'es':
          return pageTranslations.filter(page => page.localization.culture === 'es')[0].footerLanguages || english
        case 'zh':
          return pageTranslations.filter(page => page.localization.culture === 'zh')[0].footerLanguages || english
        case 'ht':
          return pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].footerLanguages || english
        case 'pt':
          return pageTranslations.filter(page => page.localization.culture === 'pt')[0].footerLanguages || english
        case 'pa':
          return pageTranslations.filter(page => page.localization.culture === 'pa')[0].footerLanguages || english
        default: 
          return english
      }
    }
    function footerConnect() {
      const pageTranslations = footerData.pageTranslations.pageTranslations;
      const english = pageTranslations.filter(page => page.localization.culture === 'en-US')[0].stayConnected;
      switch (intl.locale) {
        case 'es':
          return pageTranslations.filter(page => page.localization.culture === 'es')[0].stayConnected || english
        case 'zh':
          return pageTranslations.filter(page => page.localization.culture === 'zh')[0].stayConnected || english
        case 'ht':
          return pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].stayConnected || english
        case 'pt':
          return pageTranslations.filter(page => page.localization.culture === 'pt')[0].stayConnected || english
        case 'pa':
          return pageTranslations.filter(page => page.localization.culture === 'pa')[0].stayConnected || english
        default: 
          return english
      }
    }

    const closeModal = () => {
      setModalState(false)
    }

    return (
      <footer style={{position: 'relative', backgroundColor: '#252525'}}>
        <div className="grid-container text-white padding-y-4 tablet:padding-y-4">
          <div className="grid-row" style={{margin: '0 1rem'}}>
              {
                footerMap().map(col => { return(
                  <div className="tablet:grid-col" key={col.contentItemId}>
                    <h3>{col.title}</h3>
                    <ul className="add-list-reset">
                      {col.links.map(link => {return(
                        <li className="margin-y-1" key={link.url}>
                        <a className="text-white text-no-underline hover:text-underline" 
                          href={link.url} target="_blank" rel="noopener noreferrer">
                            {link.title}
                        </a>
                      </li>
                      )})}
                    </ul>
                  </div>
                )})
              }
            <div className="tablet:grid-col-auto margin-right-8">
                {
                  !isFaq && !isResources &&
                  <div className="margin-y-2">
                    <h3>{footerLang()}</h3>
                    <Language id={"basic-nav-section-two"}/>
                  </div>
                }
              <div className="margin-y-4">
                <h3>{footerConnect()}</h3>
                <button aria-label="facebook" className="margin-x-1" style={{backgroundColor: 'transparent', border: '0', cursor: 'pointer'}} onClick={() => {setModalUrl('https://www.facebook.com/doj.eoir/'); setModalState(true)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" data-name="Layer 1" viewBox="0 0 12.98 24"><defs/><path fill="#fff" d="M12.49 0H9.38c-3.5 0-5.76 2.32-5.76 5.91v2.72H.49a.49.49 0 00-.49.49v4a.49.49 0 00.49.49h3.13v10a.49.49 0 00.49.49H8.2a.48.48 0 00.48-.49v-10h3.66a.48.48 0 00.49-.49v-4a.49.49 0 00-.49-.49H8.68V6.32c0-1.11.27-1.67 1.71-1.67h2.1a.49.49 0 00.51-.49V.49a.48.48 0 00-.5-.49z"/></svg>
                </button>
                <button aria-label="twitter" className="margin-x-1" style={{backgroundColor: 'transparent', border: '0', cursor: 'pointer'}} onClick={() => {setModalUrl('https://twitter.com/DOJ_EOIR'); setModalState(true)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32px"  height="32px" viewBox="0 0 29.5 24"><defs/><path fill="#fff" d="M29.5 2.8c-1.1.5-2.3.8-3.5 1 1.3-.8 2.2-1.9 2.7-3.3-1.2.7-2.5 1.2-3.8 1.5-2.3-2.4-6.1-2.6-8.6-.3-1.2 1.1-1.9 2.7-1.9 4.4 0 .5 0 .9.1 1.4-4.8-.3-9.4-2.6-12.4-6.4-1.7 2.8-.8 6.3 1.8 8.1-.9 0-1.9-.3-2.7-.7v.1c0 2.9 2 5.4 4.9 6-.5.1-1.1.2-1.6.2-.4 0-.8 0-1.1-.1.7 2.3 3 4 5.6 4.1-2.1 1.7-4.8 2.6-7.5 2.6-.5 0-1 0-1.5-.1C2.8 23.1 6 24 9.3 24c11.1 0 17.2-9.2 17.2-17.2V6c1.2-.9 2.2-1.9 3-3.2z"/></svg>
                </button>
              </div>
            </div>
            <div className="tablet:grid-col-fill">
              <div className="grid-row tablet:flex-justify-end flex-align-center margin-top-2" id="extended-logo">
                <Seal />
                <em className="usa-logo__text font-serif-2xl margin-left-2">
                  EOIR
                </em>
                <Link to="/" className="text-no-underline border-left border-white margin-left-1 line-height-mono-2 tablet:margin-left-2 padding-left-1 tablet:padding-left-2 font-serif-3xs tablet:text-ls-2 tablet:font-serif-md">
                  <span className="text-white">Immigration Court <br /> Online Resource</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <LeavingDisclaimer modalState={modalState} modalUrl={modalUrl} closeModal={closeModal} />
      </footer>
    );
}
