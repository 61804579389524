import React from "react"
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

export default function Language({id, mode}) {
    const intl = useIntl();
    const languageData =  useStaticQuery(graphql`
        query {
            pageTranslations {
                pageTranslations {
                    language
                    localization {
                        culture
                    }
                }
            }
        }
    `);
    const pageTranslations = languageData.pageTranslations.pageTranslations;
    // when adding languages, be sure to add the new language to gatsby-config > gatsby-plugin-intl
    const languageName = {
        en: pageTranslations.filter(page => page.localization.culture === 'en-US')[0].language,
        es: pageTranslations.filter(page => page.localization.culture === 'es')[0].language,
        zh: pageTranslations.filter(page => page.localization.culture === 'zh')[0].language,
        ht: pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].language,
        pt: pageTranslations.filter(page => page.localization.culture === 'pt')[0].language,
        pa: pageTranslations.filter(page => page.localization.culture === 'pa')[0].language
    }

    return (
        <ul className="add-list-reset tablet:margin-y-1 text-right">
            <li className="display-inline-block position-relative">
            <button
                className="usa-nav__link"
                style={{
                    backgroundColor: 'transparent',
                    border: '0',
                    color: mode === 'light' ? '#1a1a1a' : '#fff',
                    cursor: 'pointer'
                }}
                aria-expanded="false"
                aria-controls={id}
            >
                <span className="margin-right-1">{languageName[intl.locale]}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="9px" data-name="Layer 1" viewBox="0 0 14.4 8.89"><defs/><path fill={mode === 'light' ? '#1a1a1a' : '#fff'} d="M14.4 1.69L12.71 0 7.2 5.5 1.69 0 0 1.69l7.2 7.2z"/></svg>
            </button>
            <ul id={id} className="usa-nav__submenu text-left" style={{width: '16rem'}} hidden>
                <IntlContextConsumer>
                    {({ languages, language: currentLocale }) =>
                        languages.map(language => (
                            <li className="margin-2" key={language}>
                                <a
                                onClick={() => changeLocale(language)}
                                style={{ paddingLeft: '0', fontWeight: languageName[language] === languageName[intl.locale] ? 'bold' : '400'}}
                                href="#">
                                {languageName[language]}
                                </a>
                            </li>
                        ))
                        }
                </IntlContextConsumer>
            </ul>
            </li>
        </ul>
    )
}