import React, { useState, useEffect } from "react"


export default function ScrollToTop(props) {
    const [showScroll, setShowScroll] = useState(false);
    const [hoverScroll, setHoverScroll] = useState(false);
    const [scrollTop, setScrollTop] = useState(false);

    const toggleHover = () =>{
      setHoverScroll(!hoverScroll);
    };

    useEffect(() => { 
        
        const checkScrollTop = () => {
          if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)
          } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)
          }
          if(props.el != null){
            if (!showScroll && document.getElementById(props.el).scrollTop > 40){
              setShowScroll(true)
            } else if (!showScroll && document.getElementById(props.el).scrollTop <= 40){
              setShowScroll(false)
            }
          }
        }

        if(props.el != null){
          document.getElementById(props.el).addEventListener('scroll', checkScrollTop);
        } else {
          window.addEventListener('scroll', checkScrollTop);
        }

        if(scrollTop){
          if(props.el != null){
            document.getElementById(props.el).scrollTo({top: 0, behavior: 'smooth'});
          } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
          setScrollTop(false);
        }

        return () => {
          if(props.el != null){
            document.getElementById(props.el).removeEventListener('scroll', checkScrollTop);
          } else {
            window.removeEventListener('scroll', checkScrollTop);
          }
        };
      }, [props.el, scrollTop, showScroll]
    );

    return (
          <svg id="e2e916b8-b98f-4f2c-a02e-9d34166d0f16" 
          className="scrollTop" 
          onClick={() => setScrollTop(true)} 
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          style={{
              position: props.el != null ? 'sticky' : 'fixed',
              bottom: props.el != null ? '2rem' : '5rem',
              right: props.el != null ? '1rem' :'5rem',
              width: '48px',
              height: '48px',
              cursor: 'pointer',
              float: props.el != null ? 'right' : 'none',
              display: showScroll ? 'flex' : 'none'}} 
          data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46"><title>scroll-top_icon</title><path d="M23,0A23,23,0,1,1,0,23,23,23,0,0,1,23,0Z" style={{fill:`${hoverScroll ? '#a1a1a1' : '#616161'}`}}/>
          <rect x="13" y="12" width="20" height="5" rx="1" style={{fill:"#fff"}}/>
          <path d="M21.49,35a1,1,0,0,1-1-1V28H16a1,1,0,0,1-1-1,1,1,0,0,1,.31-.73l7-6.62a1,1,0,0,1,1.38,0l7,6.62a1,1,0,0,1,0,1.42A1,1,0,0,1,30,28H25.49v6a1,1,0,0,1-1,1Z" style={{fill:"#fff"}}/></svg>
    );
}