import React from "react"
import { useIntl, FormattedMessage  } from "gatsby-plugin-intl"
import SanitizedHTML from 'react-sanitized-html';
import disclaimerStyles from './disclaimer.module.scss'
import Modal from 'react-modal';
import SealLarge from '../images/seal_large'

Modal.setAppElement('#___gatsby')
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight             : '90vh',
    maxWidth              : '90vw',
    fontSize              : '18px',
    backgroundColor       : '#ffffff'
  }
};
Modal.defaultStyles.overlay.backgroundColor = 'rgba(51, 51, 51, 0.77)';
Modal.defaultStyles.content.border = '0';
Modal.defaultStyles.content.borderRadius = '0';
Modal.defaultStyles.content.padding = '0';

export default function LeavingDisclaimer(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.closeModal();
  }
return (
    <Modal
    isOpen={props.modalState}
    onRequestClose={props.closeModal}
    shouldCloseOnOverlayClick={false}
    style={customStyles}
    contentLabel={intl.formatMessage({ id: "disclaimerHeader" })}
    >
        
        <div className="padding-3 tablet:padding-7 tablet:padding-x-10 margin-x-auto margin-y-0">
        <div className={disclaimerStyles.stackFlex}>
            <div className="text-center" style={{position: 'relative', maxWidth: '130ex'}}>
                <button style={{backgroundColor:'#ffffff', border: '0', position: 'absolute', top: '0', right:'1rem', cursor: 'pointer'}} onClick={ () => closeModal() }>
                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><title><FormattedMessage id="headerClose" /></title><path fill="#1a1a1a" fillRule="evenodd" d="M57.042 1.15l5.809 5.808a4 4 0 0 1 0 5.657L43.465 32l19.386 19.385a4 4 0 0 1 0 5.657l-5.809 5.809a4 4 0 0 1-5.657 0L32 43.465 12.615 62.851a4 4 0 0 1-5.657 0l-5.809-5.809a4 4 0 0 1 0-5.657L20.535 32 1.149 12.615a4 4 0 0 1 0-5.657l5.809-5.809a4 4 0 0 1 5.657 0L32 20.535 51.385 1.149a4 4 0 0 1 5.657 0z"/></svg>
                </button>
                <SealLarge />
                <h2 className="margin-4 font-serif-xl"><FormattedMessage id="leavingDisclaimerTitle" /></h2>
                <p><SanitizedHTML html={props.modalUrl} /></p>
                <div className="font-body-lg margin-bottom-8"><SanitizedHTML html={intl.formatMessage({ id: "leavingDisclaimerDescription" })} /></div>
                <a className="icor-btn"
                href={props.modalUrl}>
                  <FormattedMessage id="leavingDisclaimerButton" />
                </a>
            </div>
        </div>
        
        </div>
    </Modal>
)
}